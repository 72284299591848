import React from "react";
import { Link } from "react-router-dom";

// import ReactGA from 'react-ga'

import { Button, Container, Padder, Box, toast, MessageBox } from 'vandiermen-design-system'

import { Verify } from "../components/Verify"

import { host } from "../components/Host"

import { useNavigate } from "react-router-dom"

import { Check } from 'react-feather'

import useWindowDimensions from "../components/UseWindowDimensions"


export const Profile = ({ setLoading, client, setClient }) => {

    // ReactGA.pageview(window.location.pathname)

    const { width } = useWindowDimensions()

    const navigate = useNavigate()

    async function logout() {
        setLoading(true)
        let response = await fetch(`${host}/logout`)

        response = await response.json()

        console.log(response)

        if (response.succes) {
            toast.show({
                type: 'succes',
                content: response.status,
                duration: 5000
            })
        }

        setClient(null)
        navigate('/')
        setLoading(false)
    }

    return (
        <Padder top="8rem">
            <Container width={1000}>
                <Padder bottom="4rem">
                    <h1>Profile</h1>
                    <Link to="/">
                        <Button appearance="minimal" style={{ marginRight: 8 }}>Portfolio</Button>
                    </Link>
                    <Link to="/banking">
                        <Button appearance="minimal" style={{ marginRight: 8 }}>Banking</Button>
                    </Link>
                    <Button appearance="minimal" isActive={true} style={{ marginRight: 8 }}>Profile</Button>

                    <div style={{ float: 'right' }}>
                        {width > 500 ?
                            <Button onClick={logout} >Logout</Button>
                            :
                            <div style={{ position: 'fixed', bottom: 0, right: 0, width: '100%', height: '3em', backgroundColor: '#fff', paddingTop: '2em', paddingBottom: '1em', borderTop: '1px solid gainsboro', display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={logout} >Logout</Button>
                            </div>
                        }
                    </div>
                </Padder>

                <div className="grid">
                    <div className="column-small">
                        <Box>
                            <div style={{ display: 'flex', justifyContent: 'normal', alignItems: 'center' }}>
                                <h4 style={{ margin: 0 }}>Info</h4>
                                {client.verified && <p style={{ color: '#5BE2A7', marginLeft: 12, marginTop: 6 }}>Verified <Check size={18} color="#5BE2A7" /></p>}
                            </div>
                            <Padder top="1em" bottom="1em">
                                <label>Name</label>
                                <p>{`${client.name} ${client.surname}`}</p>
                                <label>Email</label>
                                <p>{client.email}</p>
                            </Padder>
                        </Box>
                    </div>
                    {!client.verified &&
                        <div className="column-wide">
                            <Verify client={client} setClient={setClient} />
                        </div>
                    }

                </div>
                <Padder bottom="7em" />
            </Container>
        </Padder>
    );
}