import React from "react";

// import ReactGA from 'react-ga'

import {
    Container,
    Navbar,
    Padder
} from 'vandiermen-design-system'

export const Terms = ({ }) => {

    // ReactGA.pageview(window.location.pathname)

    return (
        <div>
            <Container width={900}>
                <Padder top="2em">
                    <h2>Terms and Conditions</h2>

                    <label>Version 1.3</label>
                    <Padder bottom="1em">
                        <p>These Terms of Service (the "Terms") apply to the use of the services (the "Services") provided by vanDiermen Software B.V. (the "Company"), a company incorporated under the laws of the Netherlands. By using the Services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the Services.</p>
                    </Padder>

                    <h5>1. DEFINITIONS</h5>
                    <Padder bottom="1em">
                        <p>
                            In these Terms, the following terms shall have the following meanings:
                        </p>
                        <ul>
                            <li>
                                "Content" means any text, images, video, audio, or other multimedia content, software or other information or material submitted to or through the Services.
                            </li>
                            <li>
                                "User" or "you" means any person or entity who accesses or uses the Services, regardless of whether such access or use is authorized by the Company.
                            </li>
                            <li>
                                "Cryptocurrency" means a digital asset designed to work as a medium of exchange that uses cryptography for secure financial transactions, control the creation of additional units, and verify the transfer of assets.
                            </li>
                            <li>
                                "Management Fee" means a fee calculated as a percentage of the total assets under management by the Company on behalf of the User.
                            </li>
                            <li>
                                "Performance Fee" means a fee calculated as a percentage of the net profits generated by the Company's investment activities on behalf of the User.
                            </li>
                            <li>
                                "High Water Mark" means the highest net profit level reached by the Company's investment activities on behalf of the User.
                            </li>
                        </ul>
                    </Padder>

                    <h5>2. DESCRIPTION OF SERVICES</h5>
                    <Padder bottom="1em">
                        <p>
                            The Company is a provider of cryptocurrency investment services. Through the Services, the Company offers users the ability to invest in various cryptocurrencies, including but not limited to Bitcoin, Ethereum, and Litecoin. The Company also provides users with information and analysis on the cryptocurrency market and the performance of their investments.
                        </p>
                    </Padder>

                    <h5>3. ACCOUNT REGISTRATION AND SECURITY</h5>
                    <Padder bottom="1em">
                        <p>
                            In order to use the Services, you may be required to register for an account with the Company and provide certain information about yourself as prompted by the registration form. You represent and warrant that all information provided by you during the registration process is accurate and complete.
                            You are solely responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account or password. The Company reserves the right to terminate your account at any time for any reason, in its sole discretion.
                        </p>
                    </Padder>

                    <h5>4. RISK DISCLOSURE</h5>
                    <Padder bottom="1em">
                        <p>
                            Investing in cryptocurrency carries a high level of risk, and the value of your investments may fluctuate significantly. You should carefully consider whether investing in cryptocurrency is suitable for you in light of your financial condition and ability to bear risk. The Company is not responsible for any loss of funds due to market fluctuations or other factors.

                        </p>
                    </Padder>

                    <h5>5. MANAGEMENT FEE</h5>
                    <Padder bottom="1em">
                        <p>
                            The Company shall be entitled to charge a Management Fee equal to 3% of the total assets under management by the Company on behalf of the User on an annual basis. The Management Fee shall be deducted from the User's account balance.
                        </p>
                    </Padder>

                    <h5>6. PERFORMANCE FEE</h5>
                    <Padder bottom="1em">
                        <p>
                            The Company shall be entitled to charge a Performance Fee equal to 25% of any net profits generated by the Company's investment activities on behalf of the User that exceed the High Water Mark.
                        </p>
                    </Padder>

                    <h5>7. FEES AND CHARGES</h5>
                    <Padder bottom="1em">
                        <p>
                            In addition to the Management Fee and Performance Fee, the Company may charge fees for the use of the Services, including but not limited to account maintenance fees, transaction fees, and withdrawal fees. These fees will be clearly disclosed to you prior to any charge being incurred.
                        </p>
                    </Padder>

                    <h5>8. THIRD PARTY AND CUSTODIAL FEES</h5>
                    <Padder bottom="1em">
                        <p>
                            The Company may incur third-party fees for services such as custodial, trading, and other operational expenses. These fees may be based on a percentage of the assets being held in custody, a percentage of the trade value, a flat fee per trade, or an hourly basis, depending on the specific service being provided.
                            Custodial fees may be charged by a third party for the safekeeping and securing of the Company’s assets. These fees may be based on the value of the assets being held in custody or on a flat fee basis.
                            Trading fees may be charged by exchanges or other trading platforms when the Company buys or sells cryptocurrencies. These fees may be based on a percentage of the trade value or a flat fee per trade.
                        </p>
                    </Padder>

                    <h5>9. INTELLECTUAL PROPERTY</h5>
                    <Padder bottom="1em">
                        <p>
                            The Services and all content and materials included on the Services, including but not limited to text, graphics, images, software, and any other materials (the "Materials") are the property of the Company or its licensors and are protected by copyright and other intellectual property laws. You may not use any Materials for any commercial purpose without the express written consent of the Company.
                        </p>
                    </Padder>

                    <h5>10. MODIFICATION OF TERMS OF SERVICE</h5>
                    <Padder bottom="1em">
                        <p>
                        We reserve the right to modify, amend or update these terms of service at any time, without prior notice to you. Your continued use of our services after any such changes shall constitute your consent to such changes.
                        </p>
                    </Padder>

                    <h5>11. DISCLAIMER OF WARRANTIES</h5>
                    <Padder bottom="1em">
                        <p>
                            THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. THE COMPANY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                            LIMITATION OF LIABILITY
                            IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS
                        </p>
                    </Padder>

                    <h5>12. LIMITATION OF LIABILITY</h5>
                    <Padder bottom="1em">
                        <p>
                            IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS
                        </p>
                    </Padder>



                </Padder>
            </Container>
        </div>
    );
}