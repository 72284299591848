import React, { useState } from "react";
import { Box, Container, FilePicker, Padder, Button, toast, MessageBox } from "vandiermen-design-system";
import { Link, useNavigate } from "react-router-dom";
import { host } from "./Host"

export const Verify = ({ allowLater, client, setClient }) => {

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [frontIdentity, setFrontIdentity] = useState(null)
    const [backIdentity, setBackIdentity] = useState(null)
    const [selfie, setSelfie] = useState(null)

    async function submit() {

        if (frontIdentity && backIdentity && selfie) {

            setLoading(true)

            const formData = new FormData();
            formData.append('frontId', frontIdentity[0]);
            formData.append('backId', backIdentity[0]);
            formData.append('selfie', selfie[0]);

            // Upload new file.
            const response = await fetch(`${host}/client/kyc`, {
                method: 'post',
                body: formData
            })

            const data = await response.json()

            console.log(data)

            if (data.succes) {
                
                setClient(data.client)

                toast.show({
                    type: 'succes',
                    content: data.status,
                    duration: 3000
                })

                navigate('/banking')

                toast.show({
                    type: 'info',
                    content: 'Start with creating a deposit to fund your account. When you transfer the funds succesfully, we will complete the transaction and make sure your deposited funds are added to your balance.',
                    duration: 20000
                })

                setLoading(false)

                setFrontIdentity()
                setBackIdentity()
                setSelfie()
            } else {
                toast.show({
                    type: 'error',
                    content: data.status,
                    duration: 3000
                })
            }

        } else {
            toast.show({
                type: 'error',
                content: 'Not all files were uploaded',
                duration: 3000
            })
        }
    }

    return (
        <Box>
            <h4>Verify your account</h4>
            {client.beingReviewed ?
                    <MessageBox type="succes" message="You already uploaded the required documents. Please wait for a verification. Once your profile is verified, you can start investing." />
                :
                <>
                    <p>We need some information about you to verify your account. This procedure is required to start investing. Make sure your identity document is clearly visible in all pictures.</p>

                    <Padder top="3rem" bottom="3rem">
                        <label>Front side of identity document</label>

                        <FilePicker setFiles={setFrontIdentity} fileMessage={frontIdentity ? frontIdentity[0].name : "Upload valid front of passport or identity document"} isFullWidth />

                        <br />

                        <label>Back side of identity document</label>

                        <FilePicker setFiles={setBackIdentity} fileMessage={backIdentity ? backIdentity[0].name : "Upload valid back of passport or identity document"} isFullWidth />

                        <br />

                        <label>Selfie with identity document</label>

                        <FilePicker setFiles={setSelfie} fileMessage={selfie ? selfie[0].name : "Upload selfie"} isFullWidth />
                    </Padder>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {allowLater &&
                            <Link to='/'>
                                <Button style={{ marginRight: 12 }}>Later</Button>
                            </Link>
                        }
                        <Button appearance="primary" isLoading={loading} onClick={submit}>Submit</Button>
                    </div>
                </>
            }

        </Box>
    );
}