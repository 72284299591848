import React, { useState, useEffect } from "react";

// import ReactGA from 'react-ga'

import { ROIChart } from "../components/ROIChart";
import { Balance } from "../components/Balance";
import { Button, Container, Padder, MessageBox, IconButton, Box, Input, Spinner, toast } from 'vandiermen-design-system'
import { Link, useParams } from "react-router-dom";
import { ROI } from "../components/ROI";
import { host } from '../components/Host';
import { ArrowLeft } from 'react-feather'

import { Table } from 'mercury-design'

import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

export const ClientPortfolio = ({ }) => {

    console.log(window.location.pathname)

    const { clientId } = useParams()

    const [client, setClient] = useState({})
    const [labels, setLabels] = useState([])
    const [data, setData] = useState([])
    const [assets, setAssets] = useState([])
    const [lastReturn, setLastReturn] = useState()
    const [loading, setLoading] = useState(true)
    const [transferAmount, setTransferAmount] = useState('')

    const [tradeLoading, setTradeLoading] = useState(false)
    const [side, setSide] = useState('open')
    const [symbol, setSymbol] = useState('')
    const [percentage, setPercentage] = useState('')

    useEffect(() => {

        async function fetchClient() {

            const response = await fetch(`${host}/client/${clientId}`)
            if (response.ok) {
                const data = await response.json()

                console.log(data)

                if (data.succes) {
                    setClient(data.client)

                    setAssets(data.client.assets.map(asset => {
                        return {
                            asset: asset.symbol,
                            amount: asset.amount,
                            exchange: asset.exchange,
                            value: `€${asset.value.toFixed(2)}`,
                            percentage: `${asset.percentage.toFixed(2)}%`,
                            percentChange: {
                                text: `${(((asset.value / asset.totalCost) - 1) * 100).toFixed(2)}%`,
                                color: (((asset.value / asset.totalCost) - 1) * 100).toFixed(2) > 0 ? '#5BE2A7' : '#FF605C'
                            }
                        }
                    }))

                    setLabels(data.client.dailyReports.map(dailyReport => {
                        return new Date(dailyReport.date)
                    }))

                    setData(data.client.dailyReports.map(dailyReport => {
                        return dailyReport.ROI
                    }))

                    setLastReturn(data.client.dailyReports.length > 0 ? Math.round(data.client.dailyReports[data.client.dailyReports.length - 1].ROI) : 0)

                    setLoading(false)
                } else {
                    console.log(data.status)
                }
            }
        }
        fetchClient()
    }, [])

    async function transfer() {
        const data = {
            amount: transferAmount,
            clientId: client.id
        }

        let response = await fetch(`${host}/transfer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        response = await response.json()

        if (response.succes) {
            setTransferAmount('')

            toast.show({
                type: 'succes',
                content: response.status,
                duration: 3000
            })

        } else {

            toast.show({
                type: 'error',
                content: response.status,
                duration: 3000
            })
        }
    }

    async function trade() {
        if (side === 'open') {
            setTradeLoading(true)

            const data = {
                symbol: symbol,
                percentage: percentage
            }

            let response = await fetch(`${host}/client/${client.id}/position/open`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })

            response = await response.json()

            setTradeLoading(false)

            if (response.succes) {

                toast.show({
                    type: 'succes',
                    content: response.status,
                    duration: 3000
                })

            } else {

                toast.show({
                    type: 'error',
                    content: response.status,
                    duration: 3000
                })
            }
        } else if (side === 'close') {
            setTradeLoading(true)

            const data = {
                symbol: symbol
            }

            let response = await fetch(`${host}/client/${client.id}/position/close`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })

            response = await response.json()

            setTradeLoading(false)

            if (response.succes) {

                toast.show({
                    type: 'succes',
                    content: response.status,
                    duration: 3000
                })

            } else {

                toast.show({
                    type: 'error',
                    content: response.status,
                    duration: 3000
                })
            }
        } else {
            toast.show({
                type: 'error',
                content: 'Pick trading side',
                duration: 3000
            })
        }
    }

    console.log(client)

    return (
        <>
            {loading ?
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: 500 }} >
                    <Spinner />
                </div>
                :
                <Padder top="8rem">
                    <Container width={1000}>
                        <Padder bottom="4rem">
                            <div style={{ display: 'flex' }}>
                                <Link to='/firm/clients'>
                                    <IconButton style={{ marginTop: '0.7em' }} icon={<ArrowLeft></ArrowLeft>} />
                                </Link>
                                <h1 style={{ marginLeft: '0.5em' }}>{client.name} {client.surname}</h1>
                            </div>

                            <Button appearance="minimal" isActive={true} style={{ marginRight: 8 }}>Portfolio</Button>
                            <Link to={`/firm/clients/${client.id}/transactions`}>
                                <Button appearance="minimal" style={{ marginRight: 8 }}>Transactions</Button>
                            </Link>
                            <Link to={`/firm/clients/${client.id}/info`}>
                                <Button appearance="minimal" style={{ marginRight: 8 }}>Info</Button>
                            </Link>
                        </Padder>

                        <div className="grid">
                            <div className="column-small">
                                <Balance total={client.portfolioValue || 0} />
                            </div>
                            <div className="column-big">
                                <ROIChart labels={labels} data={data} />
                            </div>
                            <div className="column-small">
                                <ROI percentage={lastReturn} />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="column-big">
                                <Table
                                    name="Assets"
                                    height={350}
                                    columns={[
                                        {
                                            header: 'Asset',
                                            accessor: 'asset',
                                            index: true
                                        },
                                        {
                                            header: 'Amount',
                                            accessor: 'amount'
                                        },
                                        {
                                            header: 'Value',
                                            accessor: 'value'
                                        },
                                        {
                                            header: 'Exchange',
                                            accessor: 'exchange'
                                        },
                                        {
                                            header: 'Percentage',
                                            accessor: 'percentage'
                                        },
                                        {
                                            header: '% Change',
                                            accessor: 'percentChange'
                                        }
                                    ]}

                                    data={assets}
                                />
                            </div>
                            <div className="column-small">
                                <Box>
                                    <h5>Transfer to Coinbase</h5>
                                    <label>Transfer id</label>
                                    <p style={{ marginBottom: '1em' }}>CBAEURUBYYYJCWEXC</p>
                                    <label>Bank account</label>
                                    <p style={{ marginBottom: '1em' }}>EE47 7700 7710 0391 4626</p>
                                    <Input placeholder="enter transer amount" isFullWidth={true} value={transferAmount} onChange={e => { setTransferAmount(e.target.value) }} />
                                    <Button isFullWidth={true} onClick={transfer}>Transfer</Button>
                                </Box>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="column-big">
                                <div style={{ minHeight: 400 }}>
                                    <AdvancedRealTimeChart symbol="BTCUSDT" autosize></AdvancedRealTimeChart>
                                </div>
                            </div>
                            <div className="column-small">
                                <Box>
                                    <h5>Trade</h5>
                                    <Padder bottom="0.05em">
                                        <Button appearance="minimal" isActive={side === 'open'} onClick={() => setSide('open')}>Open</Button>
                                        <Button appearance="minimal" isActive={side === 'close'} onClick={() => setSide('close')}>Close</Button>
                                    </Padder>
                                    <br></br>
                                    <label>Symbol</label>
                                    <Input placeholder="enter symbol" isFullWidth value={symbol} onChange={e => { setSymbol(e.target.value) }} />

                                    {side === 'open' &&
                                        <>
                                            <label>Percentage</label>
                                            <Input placeholder="enter percentage" type="number" isFullWidth value={percentage} onChange={e => { setPercentage(e.target.value) }} />
                                        </>
                                    }
                                    <Button isFullWidth isLoading={tradeLoading} onClick={trade}>Submit</Button>
                                </Box>
                            </div>
                        </div>
                    </Container>
                </Padder>

            }
        </>
    );
}