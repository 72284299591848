import React, { useState } from "react";

import { Box, Chart, Button, Padder } from 'vandiermen-design-system'

export const ROIChart = ({ labels, data }) => {

    const [period, setPeriod] = useState('week')

    let shownLabels = []
    let shownData = []

    switch (period) {
        case 'week':
            shownLabels = labels.map(date => date.toLocaleString("default", { weekday: "short" })).slice(-7)
            shownData = data.slice(-7)
            break;

        case 'month':
            shownLabels = labels.map(date => new Date(date).getDate()).slice(-31).filter((label, index) => index % 3 === 0)
            shownData = data.slice(-31).filter((label, index) => index % 3 === 0)
            break;

        case 'year':
            for(let i=0; i < labels.length; i++){
                console.log(new Date(labels[i]).getDate())

                // shownLabels.
                if(new Date(labels[i]).getDate() === 1){
                    shownLabels.push(labels[i].toLocaleString("default", { month: "short" }))
                    shownData.push(data[i])
                }
            }
            
            shownLabels = shownLabels.slice(-12)
            shownData = shownData.slice(-12)
            break;
    
        default:
            break;
    }

    let trend = 'uptrend'

    if (shownData.length > 1){
        if (shownData[0] > 0){
            let change = (shownData.slice(-1) / shownData[0]) -1
            trend = change >= 0 ? 'uptrend' : 'downtrend'

            console.log(change)
        }
    }

    console.log(shownLabels)
    console.log(shownData)

    return (
        <Box>
            <div style={{ float: 'right' }} >
                <Button appearance="minimal" onClick={() => setPeriod('week')} isActive={period === 'week'} style={{ marginRight: 8 }}>Week</Button>
                <Button appearance="minimal" onClick={() => setPeriod('month')} isActive={period === 'month'} style={{ marginRight: 8 }}>Month</Button>
                <Button appearance="minimal" onClick={() => setPeriod('year')} isActive={period === 'year'} >Year</Button>
            </div>
            <Padder top="2rem">
                <Chart
                    options={{
                        height: 350,
                        axisX: {
                            labelInterpolationFnc: function (value, index) {
                                return index % (period === 'year' ? 1 : 2) === 0 ? value : null;
                            },
                            showGrid: false,
                            // showLabel: false
                        },
                        axisY: {
                            labelInterpolationFnc: function (value, index) {
                                return index % 2 === 0 ? value : null;
                            },
                            showGrid: false,
                            // showLabel: false
                        },
                        showArea: true,
                        showLine: true,
                        showPoint: true,
                        fullWidth: true,
                        fullHeight: true
                    }}

                    data={{
                        labels: shownLabels,
                        series: [
                            {
                                className: trend,
                                name: 'return',
                                data: shownData
                            }
                        ]
                    }}

                    // data={{
                    //     labels: ['Mo', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mo', 'Tue', 'Wed'],
                    //     series: [
                    //         {
                    //             className: 'downtrend',
                    //             name: 'return',
                    //             data: [2, 6, 2, 15, 10, 5, 18, 13, 22, 35]
                    //         }
                    //     ]
                    // }}

                    type='Line' />
            </Padder>
        </Box>
    );
}