import React from "react";

import { Box } from "vandiermen-design-system";

export const ROI = ({ percentage }) => {
    return ( 
        <Box>
            <h4>Performance</h4>

            {percentage >= 0 ?
                <h2 style={{ color: '#5BE2A7' }}>{Math.round(percentage)}%</h2>
                :
                <h2 style={{ color: '#F95656' }}>{Math.round(percentage)}%</h2>
            }
        </Box>
    );
}