import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
// import ReactGA from 'react-ga'

// ReactGA.initialize('UA-215856744-1', {
//     'cookieDomain': 'auto',
//     'debug': !(process.env.NODE_ENV === "production")
// })

import ReactGA from "react-ga4";

ReactGA.initialize("G-3VHT0JHSZT");

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
    ,
    document.getElementById('root')
);