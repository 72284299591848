import React, { useState, useEffect } from 'react';

import ReactGA from 'react-ga'

import { Routes, Route } from 'react-router-dom'

import { Portfolio } from './pages/Portfolio'
import { Banking } from './pages/Banking'
import { Profile } from './pages/Profile'
import { Landing } from './pages/Landing'
import { Login } from './pages/Login'
import { Signup } from './pages/Signup'
import { FirmLogin } from './pages/FirmLogin';
import { FirmDashboard } from './pages/FirmDashboard'
import { Clients } from './pages/Clients';
import { Documents } from './pages/Documents';
import { TransactionManager } from './pages/TransactionManager';
import { Terms } from './pages/Terms';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { Disclosures } from './pages/Disclosures';
import { Privacy } from './pages/Privacy';
import { ClientPortfolio } from './pages/ClientPortfolio';
import { ClientBanking } from './pages/ClientBanking';
import { ClientInfo } from './pages/ClientInfo';

import { Verify } from './components/Verify';

import { Container, Spinner, Navbar } from "vandiermen-design-system";

import logo from './assets/logo-1.png';

import { host } from './components/Host';

import './styles/dashboard.css'

function App() {

    const [loading, setLoading] = useState(true)

    const [client, setClient] = useState(null)

    const [firmLoggedIn, setFirmLoggedIn] = useState(false)

    // useEffect(() => {
    //     ReactGA.pageview(window.location.pathname)
    //     console.log(window.location.pathname)
    // }, [window.location.pathname])

    // Fetch on page load once.
    useEffect(() => {

        async function fetchClient() {

            const response = await fetch(`${host}/client`)
            if (response.ok) {
                const data = await response.json()

                console.log(data)

                if (data.succes) {
                    setClient(data.client)

                } else {
                    console.log(data.status)
                }

                setLoading(false)
            }
        }
        async function fetchFirmLoggedIn() {

            const response = await fetch(`${host}/firm/auth`)
            if (response.ok) {
                const data = await response.json()

                console.log(data)

                if (data.succes) {
                    setFirmLoggedIn(true)
                } else {
                    console.log(data.status)
                }

            }
        }
        fetchClient()
        fetchFirmLoggedIn()
    }, [])

    return (
        <>
            {loading ?
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: 500 }} >
                    <Spinner />
                </div>
                :
                <>
                    {!client && !firmLoggedIn && <Navbar logo={logo}/>}

                    <Routes>
                        {client ?
                            <>
                                <Route path="/" element={<Portfolio setLoading={setLoading} client={client} />} />
                                <Route path="/banking" element={<Banking client={client} />} />
                                <Route path="/profile" element={<Profile setLoading={setLoading} client={client} setClient={setClient} />} />
                                {!client.verified && <Route path="/verify" element={<Container width={700}><Verify allowLater client={client} setClient={setClient} /></Container>} />}
                            </>
                            :
                            <>
                                <Route path="/" element={<Landing />} />
                                <Route path="/login" element={<Login setClient={setClient} />} />
                                <Route path="/signup" element={<Signup setClient={setClient} />} />
                                <Route path="/terms" element={<Terms />} />
                                <Route path="/disclosures" element={<Disclosures />} />
                                {/* <Route path="/about" element={ <About />} /> */}
                                <Route path="/contact" element={<Contact />} />
                                <Route path="/privacy" element={<Privacy />} />

                                <Route path="firm">
                                    <Route path="login" element={<FirmLogin setFirmloggedIn={setFirmLoggedIn} />} />

                                    {firmLoggedIn &&
                                        <>
                                            <Route path="dashboard" element={<FirmDashboard setLoading={setLoading} setFirmLoggedIn={setFirmLoggedIn} />} />
                                            <Route path="clients" >
                                                <Route index={true} element={<Clients />} />
                                                <Route path=":clientId" >
                                                    <Route index={true} element={<ClientPortfolio />} />
                                                    <Route path="transactions" element={<ClientBanking />} />
                                                    <Route path="info" element={<ClientInfo />} />
                                                </Route>
                                            </Route>
                                            <Route path="documents" element={<Documents />} />
                                            <Route path="transactions" element={<TransactionManager />} />
                                        </>
                                    }
                                </Route>
                            </>
                        }
                        <Route path="*" element={<div style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: 500 }}><h1>Oops 404...</h1></div>} />
                    </Routes>
                </>
            }
        </>
    );
}

export default App;
