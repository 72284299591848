import React from 'react'
import { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

import {
    Input,
    Button,
    Message,
    Container,
    Padder,
    Box,
    Dialog,
    toast
} from 'vandiermen-design-system'

import useWindowDimensions from '../components/UseWindowDimensions'

import { Document } from '../components/Document'
import { host } from '../components/Host'

export const Documents = ({ }) => {

    const width = useWindowDimensions()

    const [verifyOpen, setVerifyOpen] = useState(false)
    const [rejectOpen, setRejectOpen] = useState(false)

    const [client, setClient] = useState()

    const [clients, setClients] = useState([])
 
    // Fetch on page load once.
    useEffect(() => {
        async function fetchClients() {

            const response = await fetch(`${host}/clients`, { credentials: 'include' })
            if (response.ok) {
                const data = await response.json()

                console.log(data)

                if (data.succes) {
                    setClients(data.clients)

                } else {
                    console.log(data.status)
                }
            }
        }
        fetchClients()
    }, [])

    async function verifyClient() {
        const data = {
            clientId: client.id
        }

        let response = await fetch(`${host}/client/verify`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include'
        })

        response = await response.json()

        if (response.succes) {

            toast.show({
                type: 'succes',
                content: response.status,
                duration: 5000
            })

            setVerifyOpen(false)

            setClients(clients.filter(x => x.id !== client.id))

        } else {

            toast.show({
                type: 'error',
                content: response.status,
                duration: 3000
            })
        }
    }

    async function rejectClient() {
        const data = {
            clientId: client.id
        }

        let response = await fetch(`${host}/client/reject`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        response = await response.json()

        console.log(response.status)

        if (response.succes) {

            toast.show({
                type: 'succes',
                content: response.status,
                duration: 5000
            })

            setRejectOpen(false)

            setClients(clients.filter(x => x.id !== client.id))

        } else {

            toast.show({
                type: 'error',
                content: response.status,
                duration: 3000
            })
        }
    }

    return (
        <>
            <Dialog
                content={<label>Are you sure you want to verify this client?</label>}
                open={verifyOpen}
                title="Confirm verification"
                close={() => setVerifyOpen(false)}
                confirm={verifyClient}
            />

            <Dialog
                content={<label>Are you sure you want to reject this client?</label>}
                open={rejectOpen}
                title="Confirm rejection"
                appearance="danger"
                close={() => setRejectOpen(false)}
                confirm={rejectClient}
            />

            <Padder top="8rem">
                <Container width={1100}>
                    <div style={{ display: 'flex' }}>
                        <Padder top="10rem" right="2rem">
                            <Link to="/firm/dashboard">
                                <Button appearance="minimal" style={{ marginBottom: 12 }}>Dashboard</Button>
                            </Link>
                            <br />
                            <Link to="/firm/clients">
                                <Button appearance="minimal" style={{ marginBottom: 12 }}>Clients</Button>
                            </Link>
                            <br />
                            <Link to="/firm/documents">
                                <Button appearance="minimal" isActive style={{ marginBottom: 12 }}>Documents</Button>
                            </Link>
                            <br />
                            <Link to="/firm/transactions">
                                <Button appearance="minimal" style={{ marginBottom: 12 }}>Transactions</Button>
                            </Link>
                        </Padder>
                        <div>
                            <Container width={900}>
                                <h1>Documents</h1>
                                {clients.map(client => {

                                    if (!client.verified && client.beingReviewed) {
                                        return (
                                            <Box>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <h4>KYC info</h4>
                                                        <div>
                                                            <Padder bottom="2em" right="6em">
                                                                <label>Full name</label>
                                                                <p>{`${client.name} ${client.surname}`}</p>
                                                                <label>Email</label>
                                                                <p>{client.email}</p>
                                                            </Padder>
                                                            <Button onClick={() => { setVerifyOpen(true); setClient(client) }} style={{ marginRight: 8 }}>Verify</Button>
                                                            <Button onClick={() => { setRejectOpen(true); setClient(client) }} intent="danger">Reject</Button>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                        <Padder right="2em">
                                                            <Document url={client.kycInfo.selfieUrl} width={300} height={300} />
                                                        </Padder>
                                                        <div>
                                                            <Padder bottom="2em">
                                                                <Document url={client.kycInfo.frontIdUrl} width={300} height={300} />
                                                            </Padder>
                                                            <Document url={client.kycInfo.backIdUrl} width={300} height={300} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Box>
                                        );
                                    }
                                })}
                            </Container>
                        </div>
                    </div>
                </Container>
            </Padder>
        </>
    );
}