import React from 'react'
import { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

import {
    Input,
    Button,
    Message,
    Container,
    Padder,
    Box,
    Spinner
} from 'vandiermen-design-system'

import { Table } from 'mercury-design'

import useWindowDimensions from '../components/UseWindowDimensions'

import { host } from '../components/Host'

export const Clients = ({ }) => {

    const width = useWindowDimensions()

    const [loading, setLoading] = useState(true)

    const [clients, setClients] = useState([])

    // Fetch on page load once.
    useEffect(() => {
        async function fetchClients() {

            const response = await fetch(`${host}/clients`)
            if (response.ok) {
                const data = await response.json()

                console.log(data)

                if (data.succes) {
                    setLoading(false)
                    setClients(data.clients)
                } else {
                    console.log(data.status)
                }
            }
        }
        fetchClients()
    }, [])

    const clientData = clients.map(client => {

        let verified = {
            text: '',
            color: '#5BE2A7'
        }

        if (client.verified){
            verified = {
                text: 'Verified',
                color: '#5BE2A7'
            }
        }

        return {
            first: client.name,
            last: client.surname,
            email: client.email,
            balance: `€${client.portfolioValue.toFixed(2)}`,
            verified: verified,
            manage: {
                text: 'Manage',
                link: `/firm/clients/${client.id}`
            }
        }
    })

    const totalBalance = clients.reduce((prev, current) => {
        return prev + current.portfolioValue
    }, 0)

    console.log(totalBalance)

    return (
        <>
            <Padder top="8rem">
                <Container width={1100}>
                    <div style={{ display: 'flex' }}>
                        <Padder top="10rem" right="2rem">
                            <Link to="/firm/dashboard">
                                <Button appearance="minimal" style={{ marginBottom: 12 }}>Dashboard</Button>
                            </Link>
                            <br />
                            <Link to="/firm/clients">
                                <Button appearance="minimal" isActive style={{ marginBottom: 12 }}>Clients</Button>
                            </Link>
                            <br />
                            <Link to="/firm/documents">
                                <Button appearance="minimal" style={{ marginBottom: 12 }}>Documents</Button>
                            </Link>
                            <br />
                            <Link to="/firm/transactions">
                                <Button appearance="minimal" style={{ marginBottom: 12 }}>Transactions</Button>
                            </Link>
                        </Padder>
                        <div>
                            <Container width={600}>
                                <h1>Clients</h1>
                                {loading ?
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: 250, minWidth: 900 }} >
                                        <Spinner />
                                    </div>
                                    :
                                    <>
                                        {/* {clients.map(client => (
                                            <Box>
                                                <Padder top="0.1em" bottom='0.5em'>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 350 }}>
                                                        <label style={{ margin: 0 }}>{`${client.name} ${client.surname}`}</label>

                                                        <Link to={`/firm/clients/${client.id}`}>
                                                            <Button size="small" appearance="minimal">Manage</Button>
                                                        </Link>
                                                    </div>
                                                </Padder>
                                                <p>€{client.portfolioValue.toFixed(2)}</p>
                                                {client.verified && <p style={{ margin: 0, color: '#5BE2A7' }}>Verified</p>}
                                            </Box>
                                        ))} */}
                                        <Table
                                            width={1000}
                                            height={500}
                                            columns={[
                                                {
                                                    header: 'First name',
                                                    accessor: 'first',
                                                    index: true
                                                },
                                                {
                                                    header: 'Last name',
                                                    accessor: 'last',
                                                },
                                                {
                                                    header: 'Email',
                                                    accessor: 'email'
                                                },
                                                {
                                                    header: 'Balance',
                                                    accessor: 'balance'
                                                },
                                                {
                                                    header: '',
                                                    accessor: 'verified'
                                                },
                                                {
                                                    header: '',
                                                    accessor: 'manage',
                                                }
                                            ]}

                                            data={clientData}
                                        />
                                        <Padder top="1em">
                                            <label>Total balance: €{totalBalance.toFixed(2)}</label>
                                        </Padder>
                                    </>
                                }
                            </Container>
                        </div>
                    </div>
                </Container>
            </Padder>
        </>
    );
}