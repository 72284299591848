import React from "react";

// import ReactGA from 'react-ga'

import {
    Container,
    Navbar,
    Padder
} from 'vandiermen-design-system'

export const Disclosures = ({ }) => {

    // ReactGA.pageview(window.location.pathname)

    return (
        <div>
            <Container width={900}>
                <Padder top="2em">
                    <h2>Disclosures</h2>
                    <p>
                        Past performance is no guarantee of future performance.
                        Cryptocurrency is highly speculative in nature, involves a high degree of risks, such as volatile market price swings, market manipulation, flash crashes, and cybersecurity risks. Cryptocurrency is not regulated or is lightly regulated in most countries. Cryptocurrency trading can lead to large, immediate and permanent loss of financial value. You should have appropriate knowledge and experience before engaging in cryptocurrency trading.
                    </p>
                </Padder>
            </Container>
        </div>
    );
}