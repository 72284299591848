import React from 'react'
import { useState } from 'react'

import {
    Input,
    Button,
    Message,
    Container,
    Padder,
    toast,
    Navbar
} from 'vandiermen-design-system'

import useWindowDimensions from '../components/UseWindowDimensions'

import { host } from '../components/Host'

import { useNavigate } from "react-router-dom"

export const FirmLogin = ({ setFirmloggedIn }) => {

    // Form data.
    const [password, setPassword] = useState('')

    // Errors.
    const [passwordError, setPasswordError] = useState(null)

    // Loading.
    const [loading, setLoading] = useState(false)

    // Window.
    const { width } = useWindowDimensions()

    const navigate = useNavigate()

    // Submit form. 
    const submit = async () => {
        setLoading(true)

        const data = {
            password: password
        }

        let response = await fetch(`${host}/firm/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        response = await response.json()

        setLoading(false)

        if (response.succes) {
            setFirmloggedIn(true)

            toast.show({
                type: 'succes',
                content: response.status,
                duration: 5000
            })

            navigate('/firm/dashboard')

        } else {

            toast.show({
                type: 'error',
                content: response.status,
                duration: 3000
            })

            setPasswordError(response.err.password)
        }

    }

    return (
        <>
            <Container width={400}>
                <Padder top="2em" bottom="9em">
                    <h3 style={{ marginBottom: 24 }}>Firm Login</h3>

                    <label style={{ marginLeft: '0.4rem' }}>Password</label>
                    <br />
                    <Input
                        placeholder="Enter password"
                        type="password"
                        isFullWidth={true}
                        disabled={loading}
                        isInvalid={passwordError}
                        value={password}
                        onChange={e => { setPassword(e.target.value) }}
                    />

                    {passwordError && !loading && <Message type="error" message={passwordError} />}

                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 12 }}>
                        <Button onClick={submit} isLoading={loading} isFullWidth={width < 500}>Submit</Button>
                    </div>
                </Padder>
            </Container>
        </>
    );
}