import React from "react";

// import ReactGA from 'react-ga'

import { ROIChart } from "../components/ROIChart";
import { Balance } from "../components/Balance";
import { Button, Container, Padder, MessageBox } from 'vandiermen-design-system'
import { Link } from "react-router-dom";
import { ROI } from "../components/ROI";

export const Portfolio = ({ client }) => {

    // ReactGA.pageview(window.location.pathname)

    console.log(client)

    const labels = client.dailyReports.map(dailyReport => {
        return new Date(dailyReport.date)
    })

    const data = client.dailyReports.map(dailyReport => {
        return dailyReport.ROI
    })

    const lastReturn = client.dailyReports.length > 0 ? Math.round(client.dailyReports[client.dailyReports.length - 1].ROI) : 0

    // data.push(lastReturn)

    return (
        <Padder top="8rem">
            <Container width={1000}>
                <Padder bottom="4rem">
                    <h1>Portfolio</h1>
                    <Button appearance="minimal" isActive={true} style={{ marginRight: 8 }}>Portfolio</Button>
                    <Link to="/banking">
                        <Button appearance="minimal" style={{ marginRight: 8 }}>Banking</Button>
                    </Link>
                    <Link to="/profile">
                        <Button appearance="minimal" style={{ marginRight: 8 }}>Profile</Button>
                    </Link>
                </Padder>

                {!client.verified && !client.beingReviewed && <MessageBox type="info" message="You need a verified account to start investing. Head over to Profile to complete the onboarding process." />}
                {!client.verified && client.beingReviewed && <MessageBox type="succes" message="You uploaded the required documents. Please wait for a verification. Once your profile is verified, you can start investing." />}
                <div className="grid">
                    <div className="column-small">
                        <Balance total={client.portfolioValue || 0} />
                    </div>
                    <div className="column-big">
                        <ROIChart labels={labels} data={data} />
                    </div>
                    <div className="column-small">
                        <ROI percentage={lastReturn} />
                    </div>
                </div>
            </Container>
        </Padder>
    );
}