import React, { useState, useEffect, useRef } from "react";

import { Box, Loader, Padder } from 'vandiermen-design-system'

export const Balance = ({ total }) => {


    return (
        <Box>
            <h4>Balance</h4>
            <h1 style={{ color: '#C4C4C4' }}>€{Math.floor(total)}</h1>
        </Box>
    );
}
