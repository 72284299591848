import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import ReactGA from 'react-ga'

import {
    Button,
    Container,
    Padder,
    Box,
    Dialog,
    Input,
    toast,
    MessageBox,
    Message,
    Banner,
    Spinner
} from 'vandiermen-design-system'

import { Transactions } from "../components/Transactions";
import { host } from "../components/Host";

import useWindowDimensions from "../components/UseWindowDimensions";

import gradient from '../assets/gradient.jpg'

export const Banking = ({ client }) => {

    // ReactGA.pageview(window.location.pathname)

    const { width } = useWindowDimensions()

    const [open, setOpen] = useState()
    const [transactionLoading, setTransactionLoading] = useState(false)
    const [transactionType, setTransactionType] = useState('deposit')
    const [transactionAmount, setTransactionAmount] = useState(null)

    const [transactions, setTransactions] = useState(client.transactions)

    function openDialog(type) {
        setTransactionType(type)
        setOpen(true)
    }

    async function createTransaction() {

        setTransactionLoading(true)

        const data = {
            direction: transactionType,
            amount: transactionAmount
        }

        let response = await fetch(`${host}/transaction`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        response = await response.json()

        if (response.succes) {
            setOpen(false)

            setTransactionLoading(false)

            setTransactions([...transactions, response.transaction])

            toast.show({
                type: 'succes',
                content: response.status,
                duration: 3000
            })

        } else {

            setTransactionLoading(false)

            toast.show({
                type: 'error',
                content: response.status,
                duration: 3000
            })
        }
    }

    return (
        <Padder top="8rem">
            <Container width={1000}>
                <Padder bottom="4rem">
                    <h1>Banking</h1>
                    <Link to="/">
                        <Button appearance="minimal" style={{ marginRight: 8 }}>Portfolio</Button>
                    </Link>
                    <Button appearance="minimal" isActive={true} style={{ marginRight: 8 }}>Banking</Button>
                    <Link to="/profile">
                        <Button appearance="minimal" style={{ marginRight: 8 }}>Profile</Button>
                    </Link>

                    <div style={{ float: 'right' }}>
                        {width > 600 ?
                            <>
                                <Button onClick={() => { openDialog('withdrawal') }} style={{ marginRight: 12 }}>Withdrawal</Button>
                                <Button onClick={() => { openDialog('deposit') }} appearance="primary">Deposit</Button>
                            </>
                            :
                            <div style={{ position: 'fixed', bottom: 0, right: 0, width: '100%', height: '3em', backgroundColor: '#fff', paddingTop: '2em', paddingBottom: '1em', borderTop: '1px solid gainsboro', display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={() => { openDialog('withdrawal') }} style={{ marginRight: 12 }}>Withdrawal</Button>
                                <Button onClick={() => { openDialog('deposit') }} appearance="primary">Deposit</Button>
                            </div>
                        }
                    </div>
                </Padder>
                <div className="grid">
                    <div className="column-small">
                        <Box>
                            <h4>Transfer info</h4>
                            {/* <p>To make a deposit you will have to transfer funds from your bank account to our bank account.</p> */}
                            {/* <label style={{ marginTop: 8 }}>Recipient IBAN</label>
                            <p>NL19 BUNQ 2069 6368 28</p>
                            <label>BIC/SWIFT</label>
                            <p>BUNQNL2AXXX</p> */}

                            <p>Deposit EUR to start investing. Create deposit with amount. When the deposit is created, send the right amount of EUR to the bank account listed below. To withdrawal, create withdrawal with amount. The withdrawal will be processed and the funds returned.</p>

                            <label style={{ marginTop: 8 }}>IBAN</label>

                            <Padder top="1em">
                                <Banner height={150} source={gradient}>
                                    <Padder top="4em" left="2em" right="1em">
                                        <h6 style={{ wordBreak: 'break-all', color: '#fff' }}>NL19BUNQ2069636828</h6>
                                    </Padder>
                                </Banner>
                            </Padder>


                            {/* <hr></hr>

                            <p>When making a withdrawal, make sure to enter your Ethereum adress. Please add an adress on the Ethereum network.</p>
                            
                            <hr></hr>
                            
                            <label style={{ marginTop: 8 }}>Your Ethereum wallet adress</label>
                            <Input
                                placeholder={`Enter wallet adress`}
                                value={transactionAmount}
                                type="text"
                                isFullWidth
                                onChange={e => { setTransactionAmount(e.target.value) }}
                            /> */}

                        </Box>
                    </div>
                    <div className="column-big">
                        <Transactions
                            transactions={transactions.reverse()}
                            showWarning
                        />
                    </div>
                </div>

                <Dialog
                    title={`Create ${transactionType}`}
                    content={
                        <>
                            {transactionLoading ?
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: 150 }} >
                                    <Spinner />
                                </div>
                                :
                                <>
                                    {transactionType === 'deposit' && <Message type="warning" message="Please make sure to mention the payment id in your bank transfer" />}
                                    <label>Amount</label>
                                    <Input
                                        placeholder={`Enter amount to ${transactionType}`}
                                        value={transactionAmount}
                                        type="number"
                                        isFullWidth
                                        onChange={e => { setTransactionAmount(e.target.value) }}
                                    />
                                </>
                            }
                        </>
                    }
                    open={open}
                    close={() => { setOpen(!open); setTransactionType(null) }}
                    confirmMessage="Create"
                    confirm={createTransaction}
                />
                <Padder bottom="7em" />
            </Container>
        </Padder >
    );
}