import React, { useEffect, useState } from "react";

import { Box, Message, Table, Padder, MessageBox } from "vandiermen-design-system";

import useWindowDimensions from "./UseWindowDimensions";

export const Transactions = ({ transactions, showWarning }) => {

    const { width } = useWindowDimensions()

    const [coloredStatusTransactions, setColoredStatusTransactions] = useState([])

    useEffect(() => {
        setColoredStatusTransactions(transactions.map(transaction => {
            switch (transaction.status) {
                case 'completed':
                    return {
                        ...transaction,
                        amount: `€${transaction.amount}`,
                        status: {
                            text: 'Completed',
                            color: '#5BE2A7'
                        }
                    }
                case 'pending':
                    return {
                        ...transaction,
                        amount: `€${transaction.amount}`,
                        status: {
                            text: 'Pending',
                            color: '#FFBD44'
                        }
                    }
                case 'cancelled':
                    return {
                        ...transaction,
                        amount: `€${transaction.amount}`,
                        status: {
                            text: 'Cancelled',
                            color: '#FF605C'
                        }
                    }
                default:
                    break;
            }
        }).sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.date) - new Date(a.date);
          }))
    }, [transactions])

    return (
        <Box>
            <h4>Transactions</h4>
            {showWarning && <Message type="warning" message="Please make sure to mention the given payment id in your bank transfer" />}
            <Padder top="0.5em">
                {width > 500 ?
                    <Table
                        columns={[
                            {
                                header: 'Direction',
                                accessor: 'direction',
                                index: true
                            },
                            {
                                header: 'Payment id',
                                accessor: 'paymentId',
                            },
                            {
                                header: 'Amount',
                                accessor: 'amount',
                            },
                            {
                                header: 'Status',
                                accessor: 'status',
                            }
                        ]}

                        data={coloredStatusTransactions}
                    />
                    :
                    coloredStatusTransactions.map(transaction => (
                        <Box index={transaction.paymentId}>
                            <label>Direction</label>
                            <p>{transaction.direction}</p>
                            <label>Payment Id</label>
                            <p>{transaction.paymentId}</p>
                            <label>Amount</label>
                            <p>{transaction.amount}</p>
                            <label>Status</label>
                            <p style={{ color: transaction.status.color }}>{transaction.status.text}</p>
                        </Box>
                    ))
                }
            </Padder>
        </Box>
    );
}