import React, { useState, useEffect } from "react";

// import ReactGA from 'react-ga'

import { Button, Container, Padder, MessageBox, IconButton, Box, Input, Spinner, toast } from 'vandiermen-design-system'
import { Link, useParams } from "react-router-dom";
import { host } from '../components/Host';
import { ArrowLeft } from 'react-feather'
import { Check } from 'react-feather'

import { Document } from "../components/Document";

import { Table } from 'mercury-design'

export const ClientInfo = ({ }) => {

    console.log(window.location.pathname)

    const { clientId } = useParams()

    const [client, setClient] = useState({})
    const [transactions, setTransactions] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        async function fetchClient() {

            const response = await fetch(`${host}/client/${clientId}`)
            if (response.ok) {
                const data = await response.json()

                console.log(data)

                if (data.succes) {
                    setClient(data.client)

                    setLoading(false)
                } else {
                    console.log(data.status)
                }
            }
        }
        fetchClient()
    }, [])

    console.log(client)

    return (
        <>
            {loading ?
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: 500 }} >
                    <Spinner />
                </div>
                :
                <Padder top="8rem">
                    <Container width={1000}>
                        <Padder bottom="4rem">
                            <div style={{ display: 'flex' }}>
                                <Link to='/firm/clients'>
                                    <IconButton style={{ marginTop: '0.7em' }} icon={<ArrowLeft></ArrowLeft>} />
                                </Link>
                                <h1 style={{ marginLeft: '0.5em' }}>{client.name} {client.surname}</h1>
                            </div>
                            <Link to={`/firm/clients/${client.id}`}>
                                <Button appearance="minimal" style={{ marginRight: 8 }}>Portfolio</Button>
                            </Link>
                            <Link to={`/firm/clients/${client.id}/transactions`}>
                                <Button appearance="minimal" style={{ marginRight: 8 }}>Transactions</Button>
                            </Link>
                            <Button appearance="minimal" isActive={true} style={{ marginRight: 8 }}>Info</Button>
                        </Padder>
                        <div className="grid">
                            <div className="column-small">
                                <Box>
                                    <div style={{ display: 'flex', justifyContent: 'normal', alignItems: 'center' }}>
                                        <h4 style={{ margin: 0 }}>Info</h4>
                                        {client.verified && <p style={{ color: '#5BE2A7', marginLeft: 12, marginTop: 6 }}>Verified <Check size={18} color="#5BE2A7" /></p>}
                                    </div>
                                    <Padder top="1em" bottom="1em">
                                        <label>Name</label>
                                        <p>{`${client.name} ${client.surname}`}</p>
                                        <label>Email</label>
                                        <p>{client.email}</p>
                                    </Padder>
                                </Box>
                            </div>
                            <div className="column-big">
                                <Box>
                                    <h4>KYC Info</h4>
                                    {client.kycInfo ?
                                        <>
                                            <Document url={client.kycInfo.selfieUrl} width={300} height={300} />
                                            <Document url={client.kycInfo.frontIdUrl} width={300} height={300} />
                                            <Document url={client.kycInfo.backIdUrl} width={300} height={300} />
                                        </>
                                        :
                                        <label>No KYC info available</label>
                                    }

                                </Box>

                            </div>
                        </div>

                    </Container>
                </Padder>

            }
        </>
    );
}