import React from 'react'
import { useState, useEffect } from 'react'

import { Link, } from 'react-router-dom'

import {
    Input,
    Button,
    Message,
    Container,
    Padder,
    Box,
    toast
} from 'vandiermen-design-system'

import useWindowDimensions from '../components/UseWindowDimensions'

import { host } from '../components/Host'

export const TransactionManager = ({ }) => {

    const width = useWindowDimensions()

    const [transactions, setTransactions] = useState([])

    // Fetch on page load once.
    useEffect(() => {
        async function fetchReports() {

            const response = await fetch(`${host}/transactions`)
            if (response.ok) {
                const data = await response.json()

                if (data.succes) {
                    console.log(data.transactions)
                    setTransactions(data.transactions)
                } else {
                    console.log(data.status)
                }

            }
        }

        fetchReports()
    }, [])

    async function completeTransaction(transaction) {
        const data = {
            transactionId: transaction.id
        }

        let response = await fetch(`${host}/transaction/complete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        response = await response.json()

        if (response.succes) {

            toast.show({
                type: 'succes',
                content: response.status,
                duration: 5000
            })

            setTransactions(transactions.filter(x => x.id !== transaction.id))

        } else {

            toast.show({
                type: 'error',
                content: response.status,
                duration: 3000
            })
        }
    }

    async function rejectTransaction(transaction) {
        const data = {
            transactionId: transaction.id
        }

        let response = await fetch(`${host}/transaction/reject`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        response = await response.json()

        console.log(response.status)

        if (response.succes) {

            toast.show({
                type: 'succes',
                content: response.status,
                duration: 5000
            })

            setTransactions(transactions.filter(x => x.id !== transaction.id))

        } else {

            toast.show({
                type: 'error',
                content: response.status,
                duration: 3000
            })
        }
    }

    return (
        <>
            <Padder top="8rem">
                <Container width={1100}>
                    <div style={{ display: 'flex' }}>
                        <Padder top="10rem" right="2rem">
                            <Link to="/firm/dashboard">
                                <Button appearance="minimal" style={{ marginBottom: 12 }}>Dashboard</Button>
                            </Link>
                            <br />
                            <Link to="/firm/clients">
                                <Button appearance="minimal" style={{ marginBottom: 12 }}>Clients</Button>
                            </Link>
                            <br />
                            <Link to="/firm/documents">
                                <Button appearance="minimal" style={{ marginBottom: 12 }}>Documents</Button>
                            </Link>
                            <br />
                            <Link to="/firm/transactions">
                                <Button appearance="minimal" isActive style={{ marginBottom: 12 }}>Transactions</Button>
                            </Link>
                        </Padder>
                        <div>
                            <Container width={800}>
                                <h1>Transactions</h1>
                                {transactions.map(transaction => {
                                    if (!transaction.completed && transaction.status === "pending") {
                                        return (
                                            <Box>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 600 }}>
                                                    <label style={{ margin: 0 }}>{transaction.direction}</label>
                                                    <p style={{ margin: 0 }}>{transaction.client.name} {transaction.client.surname}</p>
                                                    <p style={{ margin: 0 }}>{transaction.paymentId}</p>
                                                    <p style={{ margin: 0 }}>${transaction.amount}</p>
                                                    <div>
                                                        <Button style={{ marginRight: 8 }} onClick={() => completeTransaction(transaction)}>Complete</Button>
                                                        <Button onClick={() => rejectTransaction(transaction)} intent="danger">Reject</Button>
                                                    </div>
                                                </div>
                                            </Box>
                                        );
                                    }
                                })}
                            </Container>
                        </div>
                    </div>
                </Container>
            </Padder>
        </>
    );
}