import React from 'react'
import { useState, useEffect } from 'react'

import { Link, useNavigate } from 'react-router-dom'

import {
    Input,
    Button,
    Message,
    Container,
    Padder,
    Box,
    toast,
    Spinner
} from 'vandiermen-design-system'

import useWindowDimensions from '../components/UseWindowDimensions'

import { host } from '../components/Host'
import { ROI } from '../components/ROI'

export const FirmDashboard = ({ setLoading, setFirmLoggedIn }) => {

    const width = useWindowDimensions()

    const navigate = useNavigate()

    const [reports, setReports] = useState([])

    // Fetch on page load once.
    useEffect(() => {
        async function fetchReports() {

            const response = await fetch(`${host}/reports`)
            if (response.ok) {
                const data = await response.json()

                if (data.succes) {
                    setReports(data.dailyFirmReports)
                } else {
                    console.log(data.status)
                }

            }
        }

        fetchReports()
    }, [])

    async function logout() {
        setLoading(true)
        let response = await fetch(`${host}/logout`)

        response = await response.json()

        console.log(response)

        if (response.succes) {
            toast.show({
                type: 'succes',
                content: response.status,
                duration: 5000
            })
        }

        setFirmLoggedIn(false)
        navigate('/')
        setLoading(false)
    }

    const AUM = reports.length > 0 ? Math.round(reports[reports.length - 1].AUM) : 0

    const clients = reports.length > 0 ? Math.round(reports[reports.length - 1].clients) : 0

    const fees = reports.reduce((prev, report) => {
        return prev + report.fees
    }, 0)

    return (
        <>
            <Padder top="8rem">
                <Container width={1100}>
                    <div style={{ display: 'flex' }}>
                        <Padder top="10rem" right="2rem">
                            <Link to="/firm/dashboard">
                                <Button appearance="minimal" isActive style={{ marginBottom: 12 }}>Dashboard</Button>
                            </Link>
                            <br />
                            <Link to="/firm/clients">
                                <Button appearance="minimal" style={{ marginBottom: 12 }}>Clients</Button>
                            </Link>
                            <br />
                            <Link to="/firm/documents">
                                <Button appearance="minimal" style={{ marginBottom: 12 }}>Documents</Button>
                            </Link>
                            <br />
                            <Link to="/firm/transactions">
                                <Button appearance="minimal" style={{ marginBottom: 12 }}>Transactions</Button>
                            </Link>
                        </Padder>
                        <div>
                            <Container width={900}>
                                <h1>Dashboard</h1>
                                <div className="grid">
                                    <div className="column-small">
                                        <Box width={300}>
                                            <h4>AUM</h4>
                                            <h1>€{AUM}</h1>
                                        </Box>
                                    </div>
                                    <div className="column-small">
                                        <Box width={300}>
                                            <h4>Fees</h4>
                                            <h1>€{Math.round(fees)}</h1>
                                        </Box>
                                    </div>
                                    <div className="column-small">
                                        <Box width={300}>
                                            <h4>Clients</h4>
                                            <h1>{clients}</h1>
                                        </Box>
                                    </div>
                                </div>
                                <Button onClick={logout}>Logout</Button>
                            </Container>
                        </div>
                    </div>
                </Container>
            </Padder>
        </>
    );
}