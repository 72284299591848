import React from "react";

// import ReactGA from 'react-ga'

import {
    Container,
    Padder,
    Navbar
} from 'vandiermen-design-system'

export const Contact = ({ }) => {

    // ReactGA.pageview(window.location.pathname)

    return (
        <div>
            <Container width={900}>
                <Padder top="2em">
                    <h2>Contact</h2>
                </Padder>
            </Container>
        </div>
    );
}