import React from 'react'
import { useState } from 'react'

import ReactGA from 'react-ga4'

import {
    Input,
    Button,
    Message,
    Container,
    Padder,
    Navbar,
    toast,
    DropDown
} from 'vandiermen-design-system';

import useWindowDimensions from '../components/UseWindowDimensions'

import { host } from '../components/Host'

import { useNavigate, Link } from "react-router-dom"

export const Signup = ({ setClient }) => {

    // ReactGA.pageview(window.location.pathname)

    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Sign up" });


    // Form data.
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [nationality, setNationality] = useState('')

    // Errors.
    const [nameError, setNameError] = useState(null);
    const [surnameError, setSurnameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);
    const [nationalityError, setNationalityError] = useState(null)

    // Loading.
    const [loading, setLoading] = useState(false);

    // Window.
    const { width } = useWindowDimensions()

    const navigate = useNavigate()

    // Submit form. 
    const submit = async () => {

        setLoading(true)

        const data = {
            name: name,
            surname: surname,
            email: email,
            password: password,
            password_confirmed: confirmPassword,
            nationality: nationality
        }

        let response = await fetch(`${host}/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        response = await response.json()

        if (response.succes) {
            setClient(response.client)

            toast.show({
                type: 'succes',
                content: response.status,
                duration: 5000
            })

            navigate('/verify')
        } else {

            toast.show({
                type: 'error',
                content: response.status,
                duration: 3000
            })

            setNameError(response.err.name)
            setSurnameError(response.err.surname)
            setEmailError(response.err.email)
            setPasswordError(response.err.password)
            setConfirmPasswordError(response.err.password_confirmed)
            setNationalityError(response.err.nationality)
        }

        setLoading(false)

    }

    return (
        <>
            <Container width={400}>
                <Padder top="2em" bottom="12em">
                    <h3 style={{ marginBottom: 24 }}>Sign up</h3>

                    <label style={{ marginLeft: '0.4rem' }}>Name</label>
                    <br />
                    <Input
                        placeholder="Enter name"
                        type="text"
                        isFullWidth={true}
                        disabled={loading}
                        isInvalid={nameError}
                        value={name}
                        onChange={e => { setName(e.target.value) }}
                    />

                    {nameError && !loading && <Message type="error" message={nameError}></Message>}

                    <label style={{ marginLeft: '0.4rem' }}>Surname</label>
                    <br />
                    <Input
                        placeholder="Enter surname"
                        type="text"
                        isFullWidth={true}
                        disabled={loading}
                        isInvalid={surnameError}
                        value={surname}
                        onChange={e => { setSurname(e.target.value) }}
                    />

                    {surnameError && !loading && <Message type="error" message={surnameError}></Message>}

                    <label style={{ marginLeft: '0.4rem' }}>Email</label>
                    <br />
                    <Input
                        placeholder="Enter email"
                        type="email"
                        isFullWidth={true}
                        disabled={loading}
                        isInvalid={emailError}
                        value={email}
                        onChange={e => { setEmail(e.target.value) }}
                    />

                    {emailError && !loading && <Message type="error" message={emailError}></Message>}

                    <label style={{ marginLeft: '0.4rem' }}>Password</label>
                    <br />
                    <Input
                        placeholder="Enter password"
                        type="password"
                        isFullWidth={true}
                        disabled={loading}
                        isInvalid={passwordError}
                        value={password}
                        onChange={e => { setPassword(e.target.value) }}
                    />

                    {passwordError && !loading && <Message type="error" message={passwordError}></Message>}

                    <label style={{ marginLeft: '0.4rem' }}>Confirm Password</label>
                    <br />
                    <Input
                        placeholder="Enter password"
                        type="password"
                        isFullWidth={true}
                        disabled={loading}
                        isInvalid={confirmPasswordError}
                        value={confirmPassword}
                        onChange={e => { setConfirmPassword(e.target.value) }}
                    />

                    {confirmPasswordError && !loading && <Message type="error" message={confirmPasswordError}></Message>}

                    <label style={{ marginLeft: '0.4rem' }}>Choose nationality</label>
                    <br />

                    {['United States', 'United Kingdom', 'Netherlands', 'Germany', 'Italy', 'France', 'Spain', 'India', 'Australia', 'Poland', 'Greece', 'Ireland', 'Scotland', 'Romania', 'Switzerland', 'Portugal', 'Denmark', 'Sweden', 'Norway', 'Turkey', 'Other'].map(country => (
                        <Button style={{ marginRight: '0.4rem' }} appearance="minimal" isActive={country === nationality} onClick={() => { setNationality(country) }} size="small">{country}</Button>
                    ))}

                    {nationalityError && !loading && <Message type="error" message={nationalityError}></Message>}


                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', marginTop: 12 }}>
                        <p style={{ color: '#C4C4C4', marginBottom: 12 }}>By signing up you accept the <Link to="/terms">terms</Link></p>
                        <Button onClick={submit} isLoading={loading} isFullWidth={width < 500}>Submit</Button>
                    </div>
                </Padder>
            </Container>
        </>
    );
}