import React, { useState, useEffect } from "react";

import { Banner } from 'vandiermen-design-system'

import { host } from "./Host";

export const Document = ({ url, width, height }) => {

    const [source, setSource] = useState()

    useEffect(() => {
        async function getSource() {

            let response = await fetch(`${host}/firm/file/${url}`)

            if (response.ok) {
                const data = await response.blob()
                console.log(data)

                setSource(data)
            }
        }

        getSource()
    }, [url])

    return (
        <div style={{ width: width }}>
            {/* {source && <Banner height={height} direction="top" source={URL.createObjectURL(source)}></Banner>} */}
            {source && <img width={300} src={URL.createObjectURL(source)} />}
        </div>
    );
}
