import React, { useState, useEffect } from "react";

// import ReactGA from 'react-ga'

import { ROIChart } from "../components/ROIChart";
import { Balance } from "../components/Balance";
import { Button, Container, Padder, MessageBox, IconButton, Box, Input, Spinner, toast } from 'vandiermen-design-system'
import { Link, useParams } from "react-router-dom";
import { ROI } from "../components/ROI";
import { host } from '../components/Host';
import { ArrowLeft } from 'react-feather'

import { Table } from 'mercury-design'

export const ClientBanking = ({ }) => {

    console.log(window.location.pathname)

    const { clientId } = useParams()

    const [client, setClient] = useState({})
    const [transactions, setTransactions] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        async function fetchClient() {

            const response = await fetch(`${host}/client/${clientId}`)
            if (response.ok) {
                const data = await response.json()

                console.log(data)

                if (data.succes) {
                    setClient(data.client)

                    setTransactions(data.client.transactions.map(transaction => {
                        switch (transaction.status) {
                            case 'completed':
                                return {
                                    ...transaction,
                                    amount: `€${transaction.amount}`,
                                    status: {
                                        text: 'Completed',
                                        color: '#5BE2A7'
                                    }
                                }
                            case 'pending':
                                return {
                                    ...transaction,
                                    amount: `€${transaction.amount}`,
                                    status: {
                                        text: 'Pending',
                                        color: '#FFBD44'
                                    }
                                }
                            case 'cancelled':
                                return {
                                    ...transaction,
                                    amount: `€${transaction.amount}`,
                                    status: {
                                        text: 'Cancelled',
                                        color: '#FF605C'
                                    }
                                }
                            default:
                                break;
                        }
                    }).sort(function (a, b) {
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return new Date(b.date) - new Date(a.date);
                    }))

                    setLoading(false)
                } else {
                    console.log(data.status)
                }
            }
        }
        fetchClient()
    }, [])

    console.log(client)

    return (
        <>
            {loading ?
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: 500 }} >
                    <Spinner />
                </div>
                :
                <Padder top="8rem">
                    <Container width={1000}>
                        <Padder bottom="4rem">
                            <div style={{ display: 'flex' }}>
                                <Link to='/firm/clients'>
                                    <IconButton style={{ marginTop: '0.7em' }} icon={<ArrowLeft></ArrowLeft>} />
                                </Link>
                                <h1 style={{ marginLeft: '0.5em' }}>{client.name} {client.surname}</h1>
                            </div>
                            <Link to={`/firm/clients/${client.id}`}>
                                <Button appearance="minimal" style={{ marginRight: 8 }}>Portfolio</Button>
                            </Link>
                            <Button appearance="minimal" isActive={true} style={{ marginRight: 8 }}>Transactions</Button>
                            <Link to={`/firm/clients/${client.id}/info`}>
                                <Button appearance="minimal" style={{ marginRight: 8 }}>Info</Button>
                            </Link>
                        </Padder>
                        <div className="grid">
                            <div className="column-big">
                                <Table
                                    name="Transactions"
                                    height={350}
                                    columns={[
                                        {
                                            header: 'Direction',
                                            accessor: 'direction',
                                            index: true
                                        },
                                        {
                                            header: 'Payment id',
                                            accessor: 'paymentId',
                                        },
                                        {
                                            header: 'Amount',
                                            accessor: 'amount',
                                        },
                                        {
                                            header: 'Status',
                                            accessor: 'status',
                                        }
                                    ]}

                                    data={transactions}
                                />
                            </div>
                        </div>

                    </Container>
                </Padder>

            }
        </>
    );
}